<template>
  <router-view></router-view>
</template>
<script>
import vueTawk from 'vue-tawk'

export default {
  name: 'dashboard',
  component: {
    vueTawk
  }
}
</script>
